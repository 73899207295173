import React from 'react'
import { useEffect } from 'react'

const HappyBirthday = () => {
  useEffect(() => {
    window.location.replace('https://happybirthday.eatofflimits.com')
  })
  return (<div>Nothing to see here</div>)
}

export default HappyBirthday